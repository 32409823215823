import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GoHomeButton, PrimaryButton, SecondaryButton } from '../components/Buttons.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { clearError, register } from '../redux/loginSlice.js';

import logo from '../images/tattooswap_line.png';
import FetchLoader from '../components/FetchLoader.jsx';
import conditionDocument from '../images/doc/condition.docx';
import privacyDocument from '../images/doc/privacy.docx'
import { Helmet } from 'react-helmet-async';

const RegisterPage = () => {

    //----- GESTIONE INPUT UTENTE -----//
    const [isEmailWrong, setIsEmailWrong] = useState(false);
    const [isPasswordWrong, setIsPasswordWrong] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState('');

    const initialState = {
        name: null,
        surname: null,
        email: null,
        password: null,
        basePosition: null,
        registrationOrigin: null,
    }

    //--- inputData con controllo email e numero di telefono ---//
    function isEmailValid(mailInput) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(mailInput);
    }

    const [inputData, setInputData] = useState(initialState)

    // Imposto subito registrationOrigin se presente userOrigin
    const { from } = useSelector(state => state.userOrigin);
    useEffect(() => {
        if (from) {
            setInputData(prevState => ({
                ...prevState,
                registrationOrigin: from
            }))
        }
    }, [from])

    // Debug
    useEffect(() => {
        console.log('inputData: ', inputData)
    }, [inputData])

    const handleInputData = (event) => {
        const { id, value } = event.target;

        if (id === 'email') {
            if (isEmailValid(value)) {
                setIsEmailWrong(false);
            } else {
                setIsEmailWrong(true);
            }
        }

        if (id === 'password') {
            if (value.length > 7) {
                setIsPasswordWrong(false);
            } else {
                setIsPasswordWrong(true);
            }
        }

        if (checkData) { setCheckData(false) };

        setInputData(prevInputData => ({
            ...prevInputData,
            [id]: value
        }))

    }

    const handleRepeatPassword = (event) => {
        const value = event.target.value;
        setRepeatPassword(value);
    }

    //----- SEND DATA FETCH AND HOMEPAGE REDIRECT -----//
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLogged, isLoading, error } = useSelector((state) => state.login);
    const [checkData, setCheckData] = useState(false);
    const [isCheckCondition, setIsCheckCondition] = useState(false)
    const sendData = async () => {

        setCheckData(false);
        if (
            (inputData.name) &&
            (inputData.surname) &&
            (inputData.email && !isEmailWrong) &&
            (inputData.password && !isPasswordWrong) &&
            (inputData.password === repeatPassword) &&
            isCheckCondition
        ) {
            dispatch(register(inputData));
        } else {
            setCheckData(true);
        }
    }

    if (isLogged) {
        setTimeout(() => {
            navigate('/homepage');
            window.location.reload();
        }, 5000);
    }

    if (error) {
        console.log('error: ', error);
    }

    return (
        <div className="main-container">
            <Helmet>
                <title>Registrati su Tattooswap - Unisciti alla Community!</title>
                <meta name="description" content="Registrati su Tattooswap per vendere o acquistare macchinette e attrezzatura usata per tatuatori. Unisciti alla nostra community oggi stesso!" />
                <meta name="keywords" content="registrazione tatuatori, crea account tattooswap, unisciti tattooswap, vendita attrezzatura tatuatori" />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Registrati su Tattooswap - Unisciti alla Community!" />
                <meta property="og:description" content="Crea un account su Tattooswap e accedi alla piattaforma numero uno per la compravendita di attrezzatura usata per tatuatori." />
                <meta property="og:url" content="https://www.tattooswap.it/register" />
                <meta property="og:image" content="https://tattooswap.it/images/tattooswap_line.png" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.tattooswap.it/register"></link>
                {/* Info pagina di registrazione */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Registrazione Tattooswap",
                        "description": "Pagina di registrazione per Tattooswap",
                        "mainEntity": {
                            "@type": "WebForm",
                            "name": "Form di Registrazione Tattooswap",
                            "description": "Modulo per registrarsi su Tattooswap"
                        }
                    })}
                </script>
            </Helmet>
            <GoHomeButton />
            <div className="box md:shadow-2xl">

                {
                    isLogged ? (

                        <div>
                            <h3>I tuoi dati sono stati inseriti correttamente.</h3>
                            <h3 className='text-teal-400'>Benvenuto su Tattooswap!</h3>
                            <p>Verrai indirizzato alla homepage fra 5 secondi...</p>
                        </div>

                    ) : (
                        error ? (


                            error === 'Failed to fetch' ? (
                                <>
                                    <h4 className='mb-4'>Qualcosa è andato storto...</h4>
                                    <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />

                                </>
                            ) : (
                                error === 'Utente già registrato. Se sei tu, puoi eseguire il login' ? (
                                    <>
                                        <h4 className='mb-4'>{error}</h4>
                                        <SecondaryButton text="Vai al login" click={() => { dispatch(clearError()); navigate('/login') }} />

                                    </>
                                ) : (
                                    <>
                                        <h4 className='mb-4'>{error}</h4>
                                        <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />
                                    </>
                                )
                            )
                        ) : (
                            <form id="registration-form" action="" onSubmit={(event) => { event.preventDefault(); sendData(); }}>
                                <div className='flex flex-col w-full items-center'>
                                    <h3 className="mb-4">Unisciti a </h3>
                                    <div className='w-72'>
                                        <img src={logo} alt="Tattooswap Logo" className='h-full' />
                                    </div>

                                </div>

                                <h4 className='mt-8'>Registrati compilando i campi qui sotto</h4>

                                <div className='flex flex-col'>
                                    <label htmlFor="name">Nome</label>
                                    <input type="text" id="name" name="name" autoComplete='given-name' onChange={handleInputData} />
                                </div>

                                <div className='flex flex-col'>
                                    <label htmlFor="surname">Cognome</label>
                                    <input type="text" id="surname" name="surname" autoComplete='family-name' onChange={handleInputData} />
                                </div>

                                <div className='flex flex-col'>
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" autoComplete='email' onChange={handleInputData} />
                                    <div className={isEmailWrong ? 'text-red-500 text-xs self-start' : 'text-slate-500 text-xs self-start'}>Inserisci un'email valida.</div>
                                </div>

                                <div className='flex flex-col'>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" id="password" name="password" onChange={handleInputData} />
                                    <div className={isPasswordWrong ? 'text-red-500 text-xs self-start mb-8' : 'text-slate-500 text-xs self-start'}>Inserisci una password di almeno 8 caratteri.</div>
                                </div>

                                <div className='flex flex-col'>
                                    <label htmlFor="repeatPassword">Ripeti password</label>
                                    <input type="password" id="repeatPassword" onChange={handleRepeatPassword} />
                                    {/* <div className={repeatPassword !== inputData.password ? 'text-red-500 text-xs self-start mb-8' : 'text-slate-500 text-xs self-start mb-8'}>Ripeti la password.</div> */}
                                </div>

                                <div className='mt-8 flex items-start gap-2 mb-8 border border-teal-500 p-4'>
                                    <input type="checkbox" className='text-s w-fit mt-[6px]' checked={isCheckCondition} onChange={() => setIsCheckCondition(!isCheckCondition)} />
                                    <label className='mt-0'>Ho letto e accetto le <Link to="/service-condition"><span className='text-teal-500'>Condizioni del Servizio</span></Link> e la <Link to="/privacy-policy"><span className='text-teal-500'>Privacy Policy</span></Link></label>
                                </div>

                                {checkData && <h3>Ricontrolla i dati inseriti.</h3>}
                                {checkData && <p>(Ricordati di accettare le condizioni)</p>}
                                {
                                    isLoading ? (
                                        <div className='w-full flex justify-center'>
                                            <FetchLoader />
                                        </div>
                                    ) : (
                                        <button type="submit" className='bg-slate-500 text-slate-50 px-4 py-3 rounded-lg hover:bg-slate-600 min-w-[150px]'>Invia dati</button>
                                    )
                                }
                            </form>
                        )

                    )

                }


            </div >

        </div >
    )
}

export default RegisterPage;